<template>
  <div class="certification-box u-flex-col u-row-between u-col-center">
    <div class="certification-top">
      <div class="certification-tit u-flex-col u-col-center">
        <div class="certification-font">完善信息</div>
        <div class="certification-nfont">借款通过率高达 <span>90</span>%</div>
      </div>
      <div class="certification-item">
        <div class="tip">仅用于您本人申请，您的隐私会严格保密</div>
        <van-cell-group>
          <van-field v-model="params.realName" placeholder="请输入本人姓名" />
          <van-field v-model="params.idCard" placeholder="请输入您的身份证号" />
        </van-cell-group>
      </div>
    </div>

    <van-row class="now">
      <van-col span="24">
        <van-field>
          <template #button>
            <van-button :disabled="send_now_disabled" @click="handleSubmit">
              提交
            </van-button>
          </template>
        </van-field>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import * as API_Common from "@/api/common.js";

export default {
  name: "Certification",
  data() {
    return {
      value: "",
      params: {
        realName: "",
        idCard: "",
      },
      channel: this.$route.query.source,
    };
  },
  computed: {
    send_now_disabled() {
      return !this.params.realName || !this.params.idCard;
    },
  },
  methods: {
    handleSubmit() {
      const { realName, idCard } = this.params;
      Dialog.confirm({
        title: `若信息有误将影响您的借款申请\n请确认`,
        message: `${realName}\n${idCard}`,
      }).then(() => {
        API_Common.checkIdCard(this.params).then((res) => {
          if (res.code === 200) {
            this.$router.push({
              name: "Wallet",
              query: {
                source: this.channel,
              },
            });
          } else {
            this.$toast(res.msg);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
body,
#app {
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss" scoped>
.certification-box {
  width: 100%;
  height: 100%;
}
.certification-top {
  width: 100%;
}
.certification-tit {
  margin-top: 1rem;

  .certification-font {
    color: #323233;
    font-size: 0.36rem;
  }
  .certification-nfont {
    font-size: 0.24rem;
    color: #fb8302;

    > span {
      font-size: 0.3rem;
    }
  }
}
.tip {
  color: #c3c2c6;
  font-size: 0.24rem;
}
.certification-item {
  width: 100%;
  padding: 10px 16px;
  margin-top: 0.6rem;

  ::v-deep {
    .van-hairline--top-bottom::after,
    .van-hairline-unset--top-bottom::after {
      border-top-width: 0;
    }
    .van-cell {
      padding: 0.4rem 0;
    }
    .van-cell::after {
      right: 0;
      left: 0;
    }
  }
}
.now {
  width: 100%;

  ::v-deep {
    .van-field__control {
      display: none;
    }
    .van-field__body,
    .van-field__button {
      padding-left: 0;
    }

    .van-field__button,
    .van-button--default {
      width: 100%;
      height: 0.9rem;
      background: linear-gradient(90deg, #ffbb31, #fb8302);
      font-size: 0.32rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>
